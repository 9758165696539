var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('section',{staticClass:"pt-50 pb-5"},[_c('div',{staticClass:"container pt-5 align-items-center justify-content-center m-auto"},[_c('div',{staticClass:"register login"},[_c('div',{staticClass:"card m-auto shadow"},[_c('div',{staticClass:"mt-4 text-center"},[_c('img',{staticClass:"m-auto mb-3",attrs:{"src":require("@/assets/img/logo.png"),"width":"160"}}),_c('h4',{staticClass:"fw-bold"},[_vm._v("Register Now")]),_c('span'),_c('div',{staticClass:"mt-3 inputbox"},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.username),expression:"form.username"}],staticClass:"form-control",class:{
                        'is-invalid': errors[0],
                        'is-valid': !errors[0] && !pristine,
                      },attrs:{"type":"text","placeholder":"Username"},domProps:{"value":(_vm.form.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "username", $event.target.value)}}}),_c('i',{staticClass:"fa fa-envelope-open"}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"inputbox"},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var pristine = ref.pristine;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",class:{
                        'is-invalid': errors[0],
                        'is-valid': !errors[0] && !pristine,
                      },attrs:{"type":"email","placeholder":"Email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('i',{staticClass:"fa fa-envelope-open"}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"inputbox"},[_c('validation-provider',{attrs:{"vid":"password","name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var pristine = ref.pristine;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                        'is-invalid': errors[0],
                        'is-valid': !errors[0] && !pristine,
                      },attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('i',{staticClass:"fa fa-lock"}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"inputbox"},[_c('validation-provider',{attrs:{"name":"password confirmation","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var pristine = ref.pristine;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password_confirmation),expression:"form.password_confirmation"}],staticClass:"form-control",class:{
                        'is-invalid': errors[0],
                        'is-valid': !errors[0] && !pristine,
                      },attrs:{"type":"password","placeholder":"Re-type Password"},domProps:{"value":(_vm.form.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password_confirmation", $event.target.value)}}}),_c('i',{staticClass:"fa fa-lock"}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div')]),_c('div',{staticClass:"mybtn m-auto mt-2"},[(!_vm.loading)?_c('button',{staticClass:"btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 me-3 addnewbtn allbtn",attrs:{"type":"submit"}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"small"},[_vm._v("Register Now")])])]):_c('button',{staticClass:"btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 me-3 addnewbtn allbtn",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" Loading... ")]),_c('button',{staticClass:"btn btn-danger border-0 rounded-0 px-2 mb-2 mb-lg-0",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"small"},[_vm._v("Reset")])])])]),_c('div',{staticClass:"text-center intro mt-3"},[_c('span',{staticClass:"d-block account"},[_vm._v("Already have an account?")]),_c('span',{staticClass:"contact"},[_c('router-link',{staticClass:"mail fw-bold",attrs:{"to":{ name: 'login' }}},[_vm._v("Login Now")])],1)])])])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }